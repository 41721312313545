import type { SupportedLanguage } from '@dx-ui/framework-i18n';

type IUriBuilder = {
  host: string;
  brand?: string;
  secure?: boolean;
  language?: MapLanguage;
};

export type MapLanguage = SupportedLanguage;

export class UriBuilder {
  host: string;
  brand?: string;
  secure?: boolean;
  language?: MapLanguage;

  constructor(args: IUriBuilder) {
    this.host = args.host;
    this.brand = args.brand;
    this.secure = args.secure;
    this.language = args.language;
  }

  build(href: string) {
    const { host, brand, secure, language } = this;
    const schema = secure ? 'https://' : 'http://';
    const lang = language || 'en';
    const path = brand ? `/${brand}${href}` : href;

    return `${schema}${host}/${lang}${path}`;
  }
}
